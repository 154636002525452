<template>
    <div class="clientcabin clientcabin-blog">
        <v-container class="black--text">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-10">
                    <p class="text-md-h3 text-h4 mt-8 text-center">
                        Car Dealership Marketing Strategy Example: Keyword Ideas
                        & Content Plan Boost Organic Traffic by 76.7%
                    </p>
                    <v-img
                        class="rounded-lg mb-4"
                        src="@/assets/img/clientcabin/stories/car-dealership/1.png"
                        width="100%"
                    />
                    <p>
                        An automobile dealership attracted 76.7% more website
                        visitors without spending money on ads.
                    </p>
                    <p>How?</p>
                    <p>
                        By producing high-quality articles, blog posts, videos,
                        audio ads, and infographics that focused on specific
                        relevant keywords, and sharing them on popular websites
                        over 16 months.
                    </p>
                    <p>
                        They created one campaign per month to achieve this -
                        and we’re going to take a closer look at how they did
                        it, but some context is needed first.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Auto Dealers Face Tough Competition in the Growing US
                        Automotive Industry Worth $104.1B in 2023
                    </p>
                    <p>
                        The promoted car dealership is located 50 miles outside
                        Oklahoma City. It offers new and used Subaru cars and
                        SUVs, as well as services such as car financing, vehicle
                        leasing, repair, maintenance, and replacement of genuine
                        Subaru parts and accessories.
                    </p>
                    <p>
                        With global vehicle sales projected to grow by 5.1% in
                        2023 and 3.3% in 2024, the car dealership needed a way
                        to differentiate the company’s products and services -
                        and remain attractive and relevant to customers.
                    </p>
                    <p>
                        Additionally, with the total value of the US car and
                        automobile manufacturing market projected to be $104.1
                        billion in 2023, it was crucial for car sellers to
                        increase their online visibility and attract more
                        customers to their website.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        The Oklahoma Auto Dealer Increased Organic Traffic by
                        76.7%: From 250 to 22,000 Visitors with an Effective
                        Content Plan
                    </p>
                    <p>
                        The auto dealer turned to content marketing to attract
                        more traffic to their website and increase their brand
                        awareness.
                    </p>
                    <p>
                        The marketing campaigns promoted auto dealership
                        business in various locations in Oklahoma, including
                        Tulsa, Oklahoma City, Norman, Mustang, Moore, Midwest
                        City, and Edmond.
                    </p>
                    <p>
                        The campaigns targeted customers interested in
                        purchasing or leasing a Subaru car or SUV. In addition,
                        they targeted those in need of car repair, maintenance,
                        or replacement parts.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/car-dealership/2.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        By December 2022, the auto dealer's website had 3468
                        visitors/m, up 76.7% from July 2021 (1449 visitors/m).
                    </p>
                    <p>
                        As a result, the business saw a significant increase in
                        the number of visitors who arrived at the website
                        through organic search results on search engines such as
                        Google. The increase was measured over time, from July
                        2021 to December 2022.
                    </p>
                    <p>
                        Semrush says the website had 1449 organic visitors per
                        month in July 2021.
                    </p>
                    <p>
                        By November 2022, organic visitors had increased by
                        76.7% to 2559 per month…
                    </p>
                    <p>
                        … and by December 2022, they had further increased to
                        3468 per month.
                    </p>
                    <p>
                        Consistency in content marketing is crucial for
                        sustained success, as it builds trust with your audience
                        and establishes your brand as a reliable source of
                        information.
                    </p>
                    <p>
                        However, it should be noted that car dealership website
                        traffic decreased from December 2022 onwards because
                        they stopped doing campaigns (they sold out to another
                        company).
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Topical Authority & Media Approach Elevates the Car
                        Dealership Business With Top Page 1 Rankings & Online
                        Popularity Boost
                    </p>
                    <p>
                        {{ company }} uses the influence of big brands and media
                        coverage to amplify a business’s brand voice and get it
                        heard by a massive audience. This can result in more
                        brand mentions and higher brand awareness.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/car-dealership/3.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        {{ company }} helps businesses increase their exposure
                        and credibility by creating high-quality,
                        well-researched content that can be shared across
                        various channels, such as news websites, influential
                        blogs, and podcasts.
                    </p>
                    <p>
                        Published content can make a brand look amazing and help
                        it gain more traffic, exposure, and trustworthiness.
                    </p>
                    <p>
                        {{ company }} also helps businesses rank higher on
                        Google by leveraging authority media to heighten their
                        visibility, increase their brand mentions and create
                        social signals - which are some of the biggest factors
                        Google uses to determine a website’s relevance and
                        authority.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/car-dealership/4.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        As seen in this example, campaigns helped the automotive
                        retailer rank higher on search engine results pages
                        (SERPs) for relevant keywords such as "Hudiburg Subaru,"
                        "Oklahoma City," "new/used," "dealership," and
                        "outback."
                    </p>
                    <p>
                        The campaigns’ titles overlapped with the keywords for
                        which the promoted site ranked on page 1. This indicates
                        the advertising or promotional activities undertaken by
                        the dealership have successfully brought more attention
                        to the dealership.
                    </p>
                    <p>
                        The increased visibility resulted in more people knowing
                        the dealership’s existence, its products and services,
                        and its brand.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/car-dealership/5.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        By leveraging authority media to heighten brand
                        visibility and social signals, {{ company }} can
                        increase a business’s brand mentions. This will improve
                        its chances of ranking higher in Google's search
                        results.
                    </p>
                    <p>
                        While many search engine optimizers focus on acquiring
                        backlinks to improve rankings, we as content marketers
                        understand that website traffic and user engagement are
                        more crucial.
                    </p>
                    <p>
                        Although backlinks are an essential ranking factor, it’s
                        the clicks from those links that matter.
                    </p>
                    <p>
                        At {{ company }}, we focus on generating clicks from
                        backlinks rather than just acquiring them, and we do
                        that at an affordable price for the promoted businesses:
                    </p>
                    <p>
                        By using various signals such as website traffic, user
                        engagement, expertise, authority, and trustworthiness,
                        Google determines whether a website is high quality and
                        popular.
                    </p>
                    <p>
                        This is illustrated in the
                        <router-link
                            to="/reasons/megaphone-effect"
                            target="_blank"
                        >
                            {{ company }} effect diagram.
                        </router-link>
                    </p>
                    <p class="font-weight-bold">
                        With the power of content marketing, the automotive
                        retailer increased its online visibility and attracted
                        more customers to its website.
                    </p>
                    <p class="font-weight-bold">
                        The campaigns helped the car dealership rank higher on
                        SERPs for relevant keywords. It saw a significant
                        increase in organic traffic, proving that the campaigns
                        were effective at reaching the dealership’s target
                        audience.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        How Content Marketing Helps Local Businesses Attract
                        More Customers in Competitive Markets
                    </p>
                    <p>
                        {{ company }} helps local businesses get more customers
                        by producing and sharing high-quality, engaging, and
                        informative content by selecting the right words,
                        formats, and platforms.
                    </p>
                    <p>
                        Local businesses in different niches successfully use
                        this strategy to become more popular online and increase
                        their brand reputation. As a result, they get rewarded
                        with more traffic from Google and get more visitors to
                        their physical locations.
                    </p>
                    <p>
                        If you’re looking to increase the visibility, reach, and
                        overall reputation of your brand or business on the
                        internet and make it easier for potential customers to
                        find and engage with your business online,
                        <router-link to="/info" class="font-weight-bold">
                            book an appointment with our team today
                        </router-link>
                        .
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class CarDealership extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get company() {
        return this.options?.business_name || 'Our Company';
    }
}
</script>
